<template>
  <div>
    roles
  </div>
</template>

<script>
export default {
  name: 'Roles',
};
</script>

<style scoped>

</style>
